.modal-backdrop {
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(239,241,245,0.64);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 0.3s linear,z-index 0.3s linear;
    transition: opacity 0.3s linear,z-index 0.3s linear;
    visibility: visible;
    z-index: 5000;
    opacity: 1;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.dialog{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 60%;
    min-width: 18rem;
    max-height: 80vh;
    min-height: 4rem;
    max-width: 900px;
    background-color: rgba(255,255,255,1);
    color: rgba(42,48,57,1);
    border-radius: 0.875rem;
    box-shadow: 0 2px 4px 0 #d7d9e2;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: normal;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    position: relative;
    -webkit-animation: slide-up-in 0.2s linear;
    animation: slide-up-in 0.2s linear;
    opacity: 1;
    -webkit-transform: scale(1,1) translateY(0);
    -ms-transform: scale(1,1) translateY(0);
    transform: scale(1,1) translateY(0);

    .dweVRS{
        overflow: hidden;
    }

    .rainbow_vertical-stretch{
        width: 100%;
    }

    .dialog-header{
        display: flex;
        align-items: center;
        justify-content: center;


        .dialog-header-close{
            position: absolute;
            right: 10px;
            top: 10px;
            transform: scale(.9);
        }
    }

    .dialog-body {
        .dialog-body-content{
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            background-color: white;
            .tables{

            }
        }
    }
}

 @media (max-width: 900px) {
    .dialog{
        width: 100%;
        min-height: 100vh;
        border-radius: 0;
    }
 }