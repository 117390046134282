.categorie {
	border: 3px solid rgb(0, 0, 0, 0.1);
	border-radius: 20px;
	width: 80%;
	max-width: 1200px;
	margin-bottom: 25px;
	color: white;

	.categorie-header {
		text-align: center;
		width: 100%;
		height: 200px;
		background-size: cover;
		background-attachment: fixed;
		position: relative;
		overflow: hidden;
		border-radius: 0 0 85% 85% / 30%;
    overflow: visible;
	}
	.categorie-header .overlay {
		width: 100%;
		height: 100%;
		padding: 30px;
		color: #fff;
		border-radius: 17px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		text-shadow: 1px 1px 1px #333;
		background: #2ecc71;

		h1 {
			font-size: 60px;
			margin-bottom: 20px;
		}

    span {
      font-size: 20px;
    }

		button {
			border: none;
			outline: none;
			padding: 10px 20px;
			border-radius: 50px;
			color: #333;
			background: #fff;
			margin-bottom: 50px;
			box-shadow: 0 3px 20px 0 #0000003b;
		}
		button:hover {
			cursor: pointer;
		}
	}
}
