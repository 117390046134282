/* Color-Variables */

$primary: #FA5F00;
$primary_text: #E37D0B;
$primary_opacity: rgba(250, 96, 0, 0.5);
$secondary: #FDA80D;
$tertiary: #FD230D;
$tertiary_text: #E33F0B;
$bg-overlay: #343a40;

$red: #FD230D;
$yellow: #FDA80D;

/* CSS */

html, body, #root, .App {
  -webkit-user-select: none;     
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #E5E5E5;
  color: #14213D;
  scroll-behavior: smooth;
}

#root {
  //background: linear-gradient(0deg, rgba(52,58,64,1) 0%, rgba(72,74,77,1) 100%);
  background-repeat: no-repeat!important;
  z-index: -1!important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
body {
  font-family: Montserrat, sans-serif;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
           overflow: overlay;
}

.txt-center {
  text-align: center;
}s

hr {
  border-top: 1px solid rgba(0,0,0,.1);
  width: 60%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul.ul-circles {
  list-style-type: circle;
  margin-left: 25px;
}

ul.ul-squares {
  list-style-type: square;
  margin-left: 25px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

img {
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: $bg-overlay;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-prev {
  left: 25px!important;
  z-index: 10;
}
.slick-next {
  right: 25px!important;
  z-index: 10;
}

a:link,
a:visited {
  text-decoration: none;
  color: orange;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.txt-red {
  color: red;
}
.txt-blue {
  color: blue;
}
.txt-green {
  color: green;
}
.txt-black {
  color: black;
}
.txt-grey {
  color: grey;
}
.txt-pink {
  color: pink;
}