.dialog {

    #min-height {
        height: 50px;
    }
    max-height: 80%;
    background-color: rgba(255,255,255,1);

    #scroll{
        overflow-y: scroll;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .dialog-header {
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        background-color: #343a40;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: white;
        padding-bottom: 8px;
        margin-bottom: 5px;
        text-transform: uppercase;

        .dialog-header-title{
            margin-top: 5px;
            font-weight: bold;
            font-size: 1.2rem;
        }

        .dialog-header-details{
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            flex-direction: column;
        }


    }

    .dialog-body {
        background-color: rgba(255,255,255,1);

        

        .dialog-body-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 100%;

            .inputs{
                display: flex;
                width: 90%;
                flex-direction: column;
            }

            .table-container{
                margin-top: 20px;
                width: 100%;
                .cLmxUG {
                    overflow-x: hidden;
                }

                .cANgrs{
                    cursor: pointer;
                }

                .hQsjJW{
                    border: none;
                }
                .jTrNNG{
                    border: none;
                }

                .table {
                    height: 280px;



                    td{
                        padding: 10px;
                    }
                }
            }

            .text-info {
                font-size: 1rem;
                color: #343a40 !important;
            }

            .filter-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                align-items: flex-start;

                span{
                    font-size: 1rem;
                    color: #7d8388 !important;
                    margin-bottom: 5px;
                }

                .filter-options {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    width: 100%;
                }
            }



            .seperator {
                width: 80%;
                height: 1px;
                background: #e3e3e3;
                margin-bottom: 15px;
                margin-top: 5px;
            }


            .persons {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                justify-content: center;
            }

            .fTtUXM {
                text-align: center;
                font-size: 1rem;
            }

            
        }
    }
}