.sub-categorie {
	width: 100%;
	max-width: 1200px;
	margin-bottom: 5px;
	color: white;

	.sub-categorie-header {
		text-align: center;
		width: 100%;
		background-size: cover;
		background-attachment: fixed;
		position: relative;
		overflow: hidden;
    	overflow: visible;
		background-color: #505050;
	}
	.sub-categorie-header .overlay {
		width: 100%;
		height: 100%;
		padding: 30px;
		color: #fff;
		text-shadow: 1px 1px 1px #333;
		background: #2ecc71;

		h1 {
			font-size: 60px;
			margin-bottom: 20px;
		}

    span {
      font-size: 20px;
    }

		button {
			border: none;
			outline: none;
			padding: 10px 20px;
			border-radius: 50px;
			color: #333;
			background: #fff;
			margin-bottom: 50px;
			box-shadow: 0 3px 20px 0 #0000003b;
		}
		button:hover {
			cursor: pointer;
		}
	}
}
