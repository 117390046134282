.item-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header .branding {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
}

.header .branding .title-desktop {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 6.85px;
  opacity: 0.9;
  font-size: 3.2rem;
  background: #2ecc71;
  padding: 10px;
  width: 120%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 -2px 10px 0px rgb(0 0 0 / 50%);
  margin: 0;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 30px;
  margin-bottom: 15px;

  a{
    margin-bottom: 10px;
  }
}

.summary .summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 15.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .banner-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 15.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .no-services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .no-services {
      font-size: 1rem;
      color: #a1a2a5;
      font-weight: 500;
    }
  }



  .summary-content {
    max-width: 1300px;
    width: 80%;




    #first {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    #last {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .summary-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      padding-left: 20px;
      padding-right: 20px;



      .summary-item-content {
        padding-top: 10px;
        padding-right: 10px;
      }

      .summary-item-price {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}



.trends-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator-home {
  margin-top: 20px;
  margin-bottom: 35px;
  max-width: 1300px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.header .branding .phrase {
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 10px;
}

.header .branding h4 {
  color: white;
  text-align: center;
}

.reservate {
  display: inline-block;
  color: white !important;
  font: inherit;
  text-align: center;
  height: 40px;
  font-weight: 200;
  background-color: #2ecc71;
  color: white;
  width: 220px;
  margin-bottom: 20px;
  border: none;
  padding: 0.5rem;
  outline: none;
  box-sizing: border-box;
  border-radius: 6px;
  transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.reservate:hover {
  background-color: #27ae60;
  transform: scale(1.1);
  color: white;
  cursor: pointer;
}

.reservate:active {
  background-color: #27ae60;
  color: white;
  transform: scale(1);
}

.sumup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 25px;
  .sumup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ammount {
      font-size: 1.3rem;
      font-weight: bold;
    }

    .title {
      font-size: medium;
      color: rgba(0, 0, 0, 0.8);
    }

  }





  .reservate.disabled {
    color: inherit;
    font: inherit;
    text-align: center;
    height: 40px;
    font-weight: 200;
    background-color: #505050;
    color: white;
    width: 200px;
    border: none;
    padding: 0.5rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 6px;
    transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  .reservate.disabled:hover {
    background-color: #505050;
    transform: scale(1);
    cursor: default;
  }

  .reservate.disabled:active {
    background-color: #505050;
    transform: scale(1);
  }



}

.reservation-progress-bar-container {}

.reservation-progress-bar {
  margin-top: 25px;
  width: 600px;
  margin-bottom: 45px;
  margin-top: 45px;

  .step-container {
    transform: translateY(10px);
  }

  .step-container.clickable {
    cursor: pointer;
  }

  .indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: #828382;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  .indexedStep.accomplished {
    background-color: #2ecc71;
  }
}

@media screen and (max-width: 768px) {
  .header {
    .branding {
      width: 100%;

      .title-desktop {
        font-size: 1.7rem;
        width: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        text-align: center;
      }
    }
  }

  .reservation-progress-bar {
    width: 70%;
  }

  .seperator-home {
    margin-bottom: 0;
  }

  .item-list {
    .categorie {
      width: 100%;

      .sub-categorie {
        margin-bottom: 0;
      }

      .categorie-header {
        height: auto;

        .overlay {
          h1 {
            font-size: 30px;
          }

          span {
            font-size: 15px;
          }
        }
      }

      .item-container .service {}

      .item-container {
        width: 100%;
        flex-direction: column;
        height: auto;

        .banner {
          min-height: 120px;
          width: 100%;
        }

        .item-content {
          flex-direction: column;

          .details {
            flex-direction: column;
            height: auto;

            .data {
              margin-top: 25px;
              margin-bottom: 5px;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
          }

          .booking {
            width: 95%;

            .reservate-button {
              width: 80%;
            }

            .reservate {
              width: 160px
            }
          }
        }
      }
    }
  }

  .sumup {
    margin-top: 15px;
  }

  .summary-content {
    width: 95% !important;
  }
}